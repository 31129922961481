@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: 'Material Icons', serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.link {
  color: #FFF;
}

.card.link{
  color:black

}

.card-header:first-child {
    width:"100%"
}

.link:hover {
   color: #00F;
   text-decoration: none;
}

a:-webkit-any-link {
    color: "black";
    cursor: pointer;
    text-decoration: none;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    -webkit-text-decoration-style: solid;
            text-decoration-style: solid;
    -webkit-text-decoration-style: initial;
            text-decoration-style: initial;
    -webkit-text-decoration-color: inherited;
            text-decoration-color: inherited;
    -webkit-text-decoration-color: initial;
            text-decoration-color: initial;
}


.login-form{
  background-color: white;
  margin: auto;
  margin-top: 5%;

  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow:
      0 1px 1px rgba(0,0,0,0.15),
      0 8px 1px -4px rgba(0,0,0,0.15);

  padding: 30px;
  padding-top:80px;
  padding-bottom:80px;
}


.signup-form{
  background-color: white;
  margin: auto;
  margin-top: 3%;

  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow:
      0 1px 1px rgba(0,0,0,0.15),
      0 8px 1px -4px rgba(0,0,0,0.15);

  padding: 30px;
  padding-top:40px;
  padding-bottom:40px;
}


.course-item{
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #cecece;
  font-family: Roboto, sans-serif;
  background-color: white;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  align-items: center;
  border: 1px solid #efefef;
  box-shadow:
      0 1px 1px rgba(0,0,0,0.15),
      0 5px 1px -4px rgba(0,0,0,0.15);
}

.course-item:hover {
  filter: brightness(97%);
  -webkit-filter: brightness(97%);
}

.unadded-course-item {
  background-color: rgb(247, 249, 255);
  cursor: pointer;
}

.course-list{
  /* width: 95%; */
  margin: auto;
  margin-top: 20px;
}

.nav-pills .nav-link.active {
   background-color: #28a745;
   color:white ;
}

.nav-link{
  color: #28a745;
}

.myProfileUnEditableClass{
  color:#7b7b7b;
  font-size: 17px;
}

.myProfileEditableClass{
  color:#28a745;
  font-size: 17px
}

.profile-style{
background-color: white;
margin: auto;
margin-top: 5%;

width: 90%;
border: 1px solid #efefef;
border-radius: 10px;
box-shadow:
    0 1px 1px rgba(0,0,0,0.15),
    0 8px 1px -4px rgba(0,0,0,0.15);

padding-top:40px;
padding-bottom:40px;
}

.profile-button{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.search-bar {
    padding: 10px;
}

.noOutline:focus, .noOutlineButton:active {
  outline: none !important;
  box-shadow: none;
}

.editable-row:hover {
    filter: brightness(97%);
    -webkit-filter: brightness(97%);
}

.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga';
}


/* VARIABLES */

:root {
  --main-color: #28a745;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}


/* GENERAL */

* {
  box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: #777;
    color: var(--text-color);
    background: #f9f9f9;
    background: var(--bg-color);
    position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
  background: #fff;
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: #28a745;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
}


/* GRID */

.row {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.row-middle {
  -webkit-align-items: center;
          align-items: center;
}

.col {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  max-width: 100%;
}

.col-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  text-align: left;
}

.col-center {
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.col-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: #fff;
  background: var(--neutral-color);
  border: 1px solid #eee;
  border: 1px solid var(--border-color);
}

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 1.5em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  -webkit-transform: scale(1.75);
          transform: scale(1.75);
  transition: .25s ease-out;
  color: #28a745;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: #ccc;
  color: var(--text-color-light);
  font-size: 70%;
  padding: .75em 0;
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid #eee;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: #fff;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: #f9f9f9;
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #28a745 0%,#28a745 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid #eee;
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: #ccc;
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #28a745;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-basis: calc(100%/7);
          flex-basis: calc(100%/7);
  width: calc(100%/7);
}

.indicator {
    display:"inline-block";
    background-color: "#28a745";
    height: 10;
    width: 10;
    border-radius: "50%";
    margin: 1.5;
}

