.App {
  text-align: center;
  font-family: 'Material Icons', serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link {
  color: #FFF;
}

.card.link{
  color:black

}

.card-header:first-child {
    width:"100%"
}

.link:hover {
   color: #00F;
   text-decoration: none;
}

a:-webkit-any-link {
    color: "black";
    cursor: pointer;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
}


.login-form{
  background-color: white;
  margin: auto;
  margin-top: 5%;

  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow:
      0 1px 1px rgba(0,0,0,0.15),
      0 8px 1px -4px rgba(0,0,0,0.15);

  padding: 30px;
  padding-top:80px;
  padding-bottom:80px;
}


.signup-form{
  background-color: white;
  margin: auto;
  margin-top: 3%;

  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow:
      0 1px 1px rgba(0,0,0,0.15),
      0 8px 1px -4px rgba(0,0,0,0.15);

  padding: 30px;
  padding-top:40px;
  padding-bottom:40px;
}


.course-item{
  align-items: center;
  border-bottom: 1px solid #cecece;
  font-family: Roboto, sans-serif;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #efefef;
  box-shadow:
      0 1px 1px rgba(0,0,0,0.15),
      0 5px 1px -4px rgba(0,0,0,0.15);
}

.course-item:hover {
  filter: brightness(97%);
  -webkit-filter: brightness(97%);
}

.unadded-course-item {
  background-color: rgb(247, 249, 255);
  cursor: pointer;
}

.course-list{
  /* width: 95%; */
  margin: auto;
  margin-top: 20px;
}

.nav-pills .nav-link.active {
   background-color: #28a745;
   color:white ;
}

.nav-link{
  color: #28a745;
}

.myProfileUnEditableClass{
  color:#7b7b7b;
  font-size: 17px;
}

.myProfileEditableClass{
  color:#28a745;
  font-size: 17px
}

.profile-style{
background-color: white;
margin: auto;
margin-top: 5%;

width: 90%;
border: 1px solid #efefef;
border-radius: 10px;
box-shadow:
    0 1px 1px rgba(0,0,0,0.15),
    0 8px 1px -4px rgba(0,0,0,0.15);

padding-top:40px;
padding-bottom:40px;
}

.profile-button{
  display: flex;
  align-items: center;
}

.search-bar {
    padding: 10px;
}

.noOutline:focus, .noOutlineButton:active {
  outline: none !important;
  box-shadow: none;
}

.editable-row:hover {
    filter: brightness(97%);
    -webkit-filter: brightness(97%);
}
